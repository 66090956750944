import React, { useEffect } from 'react'
import LogoWhite from '../images/logo-white.svg'
import plane from '../images/prize/plane-new.svg'
import Trophy from '../images/trophy.svg'
import { Link } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import Prizeslider from '../components/prize-slider'
import '../App.css'
import MyAccordion from '../components/Accordian'
import Testimonials from '../components/testimonials'
import BottomSlider from '../components/bottom-slider'
import SiteHeader from '../components/SiteHeader'
import Sitefooter from '../components/footer'
import useDocumentTitle from '../components/useDocumentTitle'

export default function Home() {
  useDocumentTitle('Home');
  const [scroll, setScroll] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10);
    });

}, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content')
    };
    secureLocalStorage.setItem('utmParams', JSON.stringify(utmParams));
    console.log('utmParams', utmParams);
  }, []);
  
  return (
    <>
      <SiteHeader/>
      <section className="hero-section herosec-img font-face-sh" style={{ height: '100vh ', marginTop: '-110px', padding: '170px 0 200px' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <img src={LogoWhite} alt="Logo" width={'150px'} />
            </div>
            <div className='col-12 text-center my-3'>
              <h1 className='text-light display-4 font-bold '>Pass it on</h1>
            </div>
            <div className='col-12 text-center my-3 '>
              <div className='w-50 mx-auto wt-100'>
                <h2 className='text-light fs-5 font-regular '>Join parkrun Forever and your regular donations will help keep parkrun free, for everyone, forever.</h2>
              </div>
            </div>
            <div className='col-12 text-center my-3 '>
              <div className='mx-auto'>
                <Link to={"/Support"} className='text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill'>Support now</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

     

      <section className='stories-section-title font-face-sh mt-5 bg-branding-2'>
      <div>
        <p className="transation-ease-in-out yellow-strip text-center w-100 m-0" style={scroll?{top: '-50px', position: 'relative', left: 0, bottom: 0}:{top: 'auto', position: 'fixed', left: 0, bottom: 0}}>
          <img className='hide_responsive' src={Trophy} />
          <span className='text-light fs-5 mx-md-5'>As a thank you, we will enter you into our monthly parkrun prize draw!</span>
          <img className='hide_responsive' src={Trophy} />
        </p>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='text-light fs-1 text-center mt-md-5 font-bold '>About parkrun Forever</h2>
              <div className='mx-auto w-50 wt-100'>
              <p className='text-light fs-6 text-center my-md-2 font-regular' >As parkrun grows, so too do the costs. But with your support and regular donations we can protect parkrun for future generations.</p>
              </div>
            </div>
          </div>
          <div className='row text-center home-video'>
    <iframe className="text-center my-5 mx-auto col-md-9 col-sm-12" height="540px" src="https://www.youtube.com/embed/iJT_t_bQE0w" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>

          <div className='row pt-5 pb-5'>
            <div className='col-12'>
              <div className='w-100 mx-auto text-center'>
          <Link to={"/Support"} className=' text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill'>Support now</Link>
              </div>
            </div>
          </div>
          <div className='row '>
              <div className='mx-lg-auto text-center'>
                {/* <div className='mx-auto text-center col-md-6 col-sm-12 bg-branding-1 rounded-4 position-relative' style={{top: '50%'}}>
                  <p className='text-white pt-5 font-bold display-1'>6,010,812</p>
                  <p className='text-white pb-5 rounded-4 font-regular'>People have joined parkrun since 2004</p>                  
              </div> */}
            </div>
          </div>
          <div className='position-relative' style={{top:100}}>
           <hr className='branding-1 text-center mx-auto' style={{opacity:1,border:'2px solid',width:'20%'}}/>
          </div>
        </div>
      </section>

      <section className='prizes-section font-face-sh mb-5 pb-5' style={{marginTop: '130px'}}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 mx-auto text-center'>
              <h2 className='black-clr display-4 font-semibold'>parkrun Forever</h2>
              <p className='black-clr display-4 font-semibold'>Thank You Draw</p>
              <p className='black-clr'>As a thank you for your support, you will be entered into our parkrun Forever prize draw. The draw is also free to enter should you be unable to donate.</p>
              {/* <p className='black-clr fs-4 mt-5 mb-4 font-semibold'>This month prizes:</p> */}
            </div>
          </div>
          <div className='row'>
            {/* <div className='col-md-12 col-sm-12'>
              <div className='row'>
                <div className='col-md-4 col-sm-12 m-0 py-2 bg-branding-4' style={{borderRadius: '25px 0px 0px 25px'}}>
                <img  className='w-100 h-auto px-5' src={plane} alt="Prize Image" />
                <div>
                  <p className='text-light display-5 px-5 font-semibold'>UK weekend break for two!</p>  
                  <p className='text-light fs-5 px-5 font-regular'>A parkrun weekend break for two, anywhere in the UK. Grand prize winner announced in January.</p>  

                  </div>
                </div>
                <div className='col-md-8 col-sm-12 m-0 p-0'>
                  <img className='w-100 h-100' src={require('../images/prize/1.jpeg')} alt="Prize Image" style={{borderRadius: '0 25px 25px 0'}} />
                </div>
              </div>
            </div> */}
            {/* <div className=' mx-0 px-0 mb-3'>
            <Prizeslider/>
            </div> */}
          </div>

          {/* <div className=' row my-5'>
          <MyAccordion/>
          </div> */}

          <div className='row my-3 mx-auto w-75 wt-95 text-center'>
          <Testimonials/>
          <p className='col-md-6 col-sm-12 mx-auto mt-0 mb-4 fs-2 font-semibold text-center'>Help keep parkrun free, for everyone, forever.</p>
          </div>
          <div className='w-100 mx-auto text-center'>
          <Link to={"/Support"} className='text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill'>Support now</Link>
              </div>
        </div>
      </section>

      <section className="mt-5 py-5 bg-branding-2 ">
        <div className='container mx-auto mb-5'>
          <img  width={'100%'} src={require('../images/people-joined.jpeg')} alt="" />

          <div className='col-md-6 col-sm-12 mx-auto position-relative bottom-slider' style={{zIndex:9,marginTop:-140}}>
          <BottomSlider/>
          </div>
        </div>
      </section>

   <Sitefooter/>

    </>
  )
}

